import Accordion from '@commonFolder/Accordian';
import {allFilters} from '../constants';
import BasicSearch from './components/basicsearch';
import LocaleStrings from '@language';
import Industry from './components/company/industry';
import Headcount from './components/company/headcount';
import Revenue from './components/company/revenue';
import Technology from './components/company/technology';
import Founded from './components/company/founded';
import ContactName from './components/people/contactname';
import PeopleCompanyName from './components/people/companyname';
import Department from './components/people/department';
import JobTitle from './components/people/jobtitle';
import Seniority from './components/people/seniority';
import SearchType from './components/people/search';
import ExcludePeople from './components/people/exclude';
import {connect} from 'react-redux';
import {useEffect, useState} from 'react';
import {
  clearFilter,
  saveFilter,
  toggleMinimizeFilters,
  togglingFilters,
} from '../actions';
import PeopleLocation from './components/people/location';
import SaveSearchPopup from './components/savesearchpopup';
import toast, {Toaster} from 'react-hot-toast';
import useWindowDimensions from '../../../../common/useWindowDimensions';
import {classNames} from '@commonFunction';
import IconFiter from './iconfilter';

function SearchFilter(props) {
  const [hasFilters, setHasFilters] = useState(false);
  const [openSaveSearchPopup, setOpenSaveSearchPopup] = useState(false);
  const {height, width} = useWindowDimensions();

  const [togglingSideBar, setTogglingSideBar] = useState(false);

  const [expandedAccordian, setExpandedAccordian] = useState();

  const [filtersToShow, setFiltersToShow] = useState(
    props.searchSelectedSubMenu === 'revealedcontacts'
      ? allFilters.filter(filter => filter.type !== 'searchtype')
      : allFilters,
  );

  useEffect(() => {
    if (expandedAccordian) {
      const updatedFilters = [...filtersToShow];

      const filters = updatedFilters.map(filter => {
        const newFilter =
          expandedAccordian.type === filter.type
            ? {...filter, collapsed: false}
            : {...filter, collapsed: true};
        return newFilter;
      });
      setFiltersToShow(filters);
    } else if (expandedAccordian === null) {
      //Close activated

      const updatedFilters = [...filtersToShow];

      const filters = updatedFilters.map(filter => {
        const newFilter = {...filter, collapsed: true};
        return newFilter;
      });

      setFiltersToShow(filters);
    }
  }, [expandedAccordian]);

  useEffect(() => {
    // const filters = props.savedFilters;
    // const hasExcludeFilter =
    //   filters.exclude.revealedbusinessemails ||
    //   filters.exclude.revealedpersonalemails ||
    //   filters.exclude.revealedphonenumbers ||
    //   filters.exclude.revealedtech ||
    //   filters.exclude.revealedcontacts;
    // const hasLocations =
    //   filters.location.contact.length > 0 || filters.location.hq.length > 0;
    // const hasIndustryCode = false; //filters.industry.type.length > 0;
    // const hasNofilters =
    //   filters['contactname'].length === 0 &&
    //   filters['department'].length === 0 &&
    //   filters['jobtitle'].length === 0 &&
    //   filters['companyname'].length === 0 &&
    //   filters['searchtype'].length === 0 &&
    //   filters['seniority'].length === 0 &&
    //   filters['industry'].length === 0 &&
    //   filters['technology'].length === 0 &&
    //   filters['foundedyear'].length === 0 &&
    //   filters['headcount'].length === 0 &&
    //   filters['revenue'].length === 0 &&
    //   filters['industrycode'].length === 0 &&
    //   !hasExcludeFilter &&
    //   !hasLocations &&
    //   !hasIndustryCode;
    // setHasFilters(!hasNofilters);
    // console.log('Called');
  }, [props.savedFilters]);

  const renderComponents = filter => {
    switch (filter.type) {
      // case 'company':
      //   return <CompanyName {...filter} />;
      // case 'location':
      //   return <Location {...filter} />;
      case 'industry':
        return <Industry {...filter} />;
      case 'headcount':
        return <Headcount {...filter} headcount={props.headcount} />;
      case 'revenue':
        return <Revenue {...filter} />;
      case 'technology':
        return <Technology {...filter} />;
      case 'foundedyear':
        return <Founded {...filter} />;
      // case 'excludecompany':
      //   return <ExcludeCompany {...filter} />;
      case 'contactname':
        return <ContactName {...filter} />;
      case 'companyname':
        return <PeopleCompanyName {...filter} />;
      case 'department':
        return <Department {...filter} />;
      case 'jobtitle':
        return <JobTitle {...filter} />;
      case 'seniority':
        return <Seniority {...filter} />;
      case 'searchtype':
        return <SearchType {...filter} />;
      case 'exclude':
        return <ExcludePeople {...filter} />;
      case 'location':
        return <PeopleLocation {...filter} />;

      default:
        return <BasicSearch {...filter} />;
    }
  };

  const parts = window.location.href.split('/');
  let answer = '';
  if (parts.length > 1) {
    answer = parts[parts.length - 1];
  }

  return (
    <div
      className={classNames(
        'h-full transition-all ease-in-out duration-300 overflow-y-auto scrollbar-thin scrollbar-thumb-gray-300 scrollbar-track-transparent scrollbar-thumb-rounded-full position-relative hover:cursor-pointer',
        !props.minimized
          ? 'min-w-[290px] max-w-[290px]'
          : 'min-w-[60px] max-w-[60px]',
      )}>
      <div
        className={classNames(
          'px-4 fixed z-[99] h-[60px] pt-4 pl-6 bg-white',
          !props.minimized
            ? 'min-w-[290px] max-w-[290px]'
            : 'min-w-[60px] max-w-[60px]',
        )}>
        <div
          className={classNames(
            '-ml-4 flex flex-wrap items-center sm:flex-nowrap',
            hasFilters ? 'pt-1' : 'pt-2',
          )}>
          <div
            className="ml-2 hover:cursor-pointer"
            onClick={() => {
              props.togglingFilters(true);
              props.toggleMinimizeFilters(!props.minimized);

              setTimeout(() => {
                props.togglingFilters(false);
              }, 400);
            }}>
            {/* <MenuAlt1Icon className="h-4 w-4 text-gray-900" /> */}
            <IconFiter expanded={!props.minimized} />
          </div>
          {!props.minimized ? (
            <div className="ml-2">
              <h3 className="text-sm text-gray-900">
                {LocaleStrings.search.filters}
              </h3>
            </div>
          ) : null}
          {!props.minimized ? (
            <div className="mr-2 flex-shrink-0">
              {hasFilters ? (
                <button
                  type="button"
                  onClick={() => {
                    props.clearFilter(props.savedFilters);
                  }}
                  className="relative inline-flex items-center px-3 py-2 text-xs text-primary hover:opacity-90">
                  {LocaleStrings.search.clear_filters}
                </button>
              ) : null}
              {hasFilters ? (
                <button
                  type="button"
                  onClick={() => {
                    setOpenSaveSearchPopup(true);
                  }}
                  className="relative inline-flex items-center rounded-md bg-white px-2 py-2 text-xs text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50">
                  {LocaleStrings.search.save_search}
                </button>
              ) : null}
            </div>
          ) : null}
        </div>
      </div>

      <div className={classNames('ring-1 ring-black ring-opacity-5 mt-[60px]')}>
        <table className="w-full table-fixed divide-y">
          <tbody className="divide-y divide-white bg-white">
            {filtersToShow.map((filter, index) => {
              let filtersCount = 0;
              if (filter.type === 'exclude') {
                const savedValues = props.savedFilters['exclude'];
                if (savedValues['revealedbusinessemails'] === true) {
                  filtersCount = filtersCount + 1;
                }
                if (savedValues['revealedpersonalemails'] === true) {
                  filtersCount = filtersCount + 1;
                }
                if (savedValues['revealedphonenumbers'] === true) {
                  filtersCount = filtersCount + 1;
                }
                if (savedValues['revealedtech'] === true) {
                  filtersCount = filtersCount + 1;
                }
                if (savedValues['revealedcontacts'] === true) {
                  filtersCount = filtersCount + 1;
                }
              } else if (filter.type === 'location') {
                const savedValues = props.savedFilters['location'];
                if (savedValues.contact.length > 0) {
                  filtersCount = filtersCount + savedValues.contact.length;
                }

                if (savedValues.hq.length > 0) {
                  filtersCount = filtersCount + savedValues.hq.length;
                }
              } else if (filter.type === 'foundedyear') {
                const associatedArray = props.savedFilters['foundedyear'];
                filtersCount = associatedArray ? associatedArray.length : 0;
              } else if (filter.type === 'industry') {
                let associatedArray = props.savedFilters['industry'];
                filtersCount = associatedArray ? associatedArray.length : 0;
              } else {
                const associatedArray = props.savedFilters[filter.type];
                filtersCount = associatedArray ? associatedArray.length : 0;
              }

              let savedFilters = props.savedFilters[filter.type];

              if (filter.type === 'exclude') {
                savedFilters = [];
                const {
                  revealedbusinessemails,
                  revealedpersonalemails,
                  revealedphonenumbers,
                  revealedcontacts,
                  revealedtech,
                } = props.savedFilters['exclude'];
                if (revealedbusinessemails) {
                  savedFilters.push({
                    title: 'Revealed business emails',
                    action: 'include',
                  });
                }
                if (revealedpersonalemails) {
                  savedFilters.push({
                    title: 'Revealed personal emails',
                    action: 'include',
                  });
                }
                if (revealedphonenumbers) {
                  savedFilters.push({
                    title: 'Revealed phone number',
                    action: 'include',
                  });
                }
                if (revealedtech) {
                  savedFilters.push({
                    title: 'Revealed technologies',
                    action: 'include',
                  });
                }
                if (revealedcontacts) {
                  savedFilters.push({
                    title: 'Revealed contact numbers',
                    action: 'include',
                  });
                }
              } else if (filter.type === 'revenue') {
                const revenues = props.savedFilters['revenue'];
                savedFilters = [];
                revenues.forEach(item => {
                  savedFilters.push({
                    title: item,
                    action: 'include',
                  });
                });
              } else if (filter.type === 'foundedyear') {
                const founded = props.savedFilters['foundedyear'];
                savedFilters = [];
                founded.forEach(item => {
                  savedFilters.push({
                    title: item,
                    action: 'include',
                  });
                });
              } else if (filter.type === 'industry') {
                savedFilters = [];

                const industry = props.savedFilters['industry'];
                industry.forEach(item => {
                  savedFilters.push({
                    title: item.title,
                    action: item.action,
                  });
                });

                const industryCode = props.savedFilters['industrycode'];

                industryCode.forEach(item => {
                  savedFilters.push({
                    title: `${item.type} - ${item.code}`,
                    action: 'include',
                  });
                });
              }

              return (
                <tr>
                  <td key={filter.type}>
                    <Accordion
                      collapsed={filter.collapsed}
                      minimized={props.minimized}
                      key={filter.type}
                      title={filter.title}
                      {...filter}
                      content={renderComponents(filter)}
                      filtersCount={filtersCount}
                      onClear={type => {
                        let filters = props.savedFilters;
                        let filterObject = filters;
                        if (type === 'location') {
                          filterObject[type] = {contact: [], hq: []};
                        } /*else if (type === 'industrycode') {
                            filterObject[type] = {type: '', code: ''};
                          }*/ else if (type === 'foundedyear') {
                          filterObject['foundedyear'] = [];
                        } else if (type === 'revenue') {
                          filterObject['revenue'] = [];
                        } else if (type !== 'exclude') {
                          filterObject[type] = [];
                        } else if (type === 'exclude') {
                          filterObject[type] = {
                            revealedbusinessemails: false,
                            revealedpersonalemails: false,
                            revealedphonenumbers: false,
                            revealedtech: false,
                            revealedcontacts: false,
                          };
                        }
                        filters = {...filters, ...filterObject};
                        props.saveFilter(props.session, filters, () => {});
                      }}
                      savedFilters={savedFilters}
                      onOpenAccordian={accordian => {
                        setExpandedAccordian(accordian);
                      }}
                      onCloseAccordian={() => {
                        setExpandedAccordian(null);
                      }}
                    />
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
      <SaveSearchPopup
        open={openSaveSearchPopup}
        toggleOpen={(open, message) => {
          setOpenSaveSearchPopup(open);
          if (message === 'saved') {
            toast.success('Filter saved successfully');
          } else if (message === 'addedtoexisting') {
            toast.success('Filter updated successfully');
          }
        }}
      />
      <Toaster />
    </div>
  );
}
function mapStateToProps(state) {
  var {session, user, savedFilters} = state;
  return {
    session,
    user,
    savedFilters,
    searchSelectedSubMenu: state.searchSelectedSubMenu,
    minimized: state.toggleMinimizeFilters,
    browserSettings: state.deviceSize.settings,
  };
}

export default connect(mapStateToProps, {
  saveFilter,
  clearFilter,
  toggleMinimizeFilters,
  togglingFilters,
})(SearchFilter);
