import {useEffect, useState} from 'react';
import LocaleStrings from '../../../../languages';
import {clearSelections, toggleCSVSelectionPopup} from '../../actions';
import IconCompany from '../icons/company';
import IconCSV from '../icons/csv';
import IconPeople from '../icons/people';
import {connect} from 'react-redux';
import {useFilePicker} from 'use-file-picker';
import {classNames} from '@commonFunction';

function DialogSection(props) {
  return (
    <div className="border border-gray-200 rounded-lg p-2 shadow-md justify-center items-center flex flex-col gap-2 pt-2 h-60">
      <div className="p-3 border-gray-200 rounded-md shadow-md mb-4">
        {props.section === 'people' ? <IconPeople /> : <IconCompany />}
      </div>
      <div className="text-sm font-semibold text-center">
        {props.section === 'people'
          ? LocaleStrings.enrichment.people.title
          : LocaleStrings.enrichment.company.title}
      </div>
      <div className="px-8 text-gray-600 text-center text-xs">
        {props.section === 'people'
          ? LocaleStrings.enrichment.people.subTitle
          : LocaleStrings.enrichment.company.subTitle}
      </div>
      <div className="flex flex-row gap-4 py-4 w-4/5">
        <button
          type="button"
          className="btn-primary"
          onClick={() => {
            props.onSelectCSV(props.section);
          }}>
          {LocaleStrings.enrichment.selectCSV}
        </button>
        {/* <button
          type="button"
          className="btn-primary-white"
          onClick={() => {
            props.onEditSettings(props.section);
          }}>
          {LocaleStrings.enrichment.editSettings}
        </button> */}
      </div>
    </div>
  );
}

function DialogEntry(props) {
  const [section, setSection] = useState('people');

  const {openFilePicker, filesContent, loading, plainFiles} = useFilePicker({
    accept: ['.csv'],
  });
  /**
   *  readAs: 'DataURL',
    onFilesSelected: ({plainFiles, filesContent, errors}) => {
      // this callback is always called, even if there are errors
      console.log('onFilesSelected', plainFiles, filesContent, errors);
    },
   */

  useEffect(() => {
    if (!loading && filesContent.length > 0) {
      props.toggleCSVSelectionPopup({
        section,
        open: true,
        file: filesContent[0],
        originalFile: plainFiles[0],
      });
    }
  }, [filesContent, loading]);

  return (
    <div
      className={classNames(
        'flex gap-4 flex-col',
        props.embedded
          ? 'w-full px-10 py-4'
          : 'items-center content-center w-full px-10 py-4',
      )}>
      <div className={classNames('flex flex-row gap-2 items-center')}>
        <div className="w-10 h-10 bg-secondary rounded-full justify-center items-center flex">
          <div className="justify-center items-center flex">
            <IconCSV />
          </div>
        </div>
        <div>
          <div className="text-lg font-semibold">
            {LocaleStrings.enrichment.dialogTitle}
          </div>
          <div className="text-gray-600 text-sm">
            {LocaleStrings.enrichment.dialogSubtitle}
          </div>
        </div>
      </div>
      <div
        className={classNames(
          'flex gap-2 items-center',
          props.embedded ? 'justify-evenly mt-2' : 'gap-4',
        )}
        style={{
          height: props.embedded ? 'calc(100vh)' : 'calc(100vh - 400px)',
        }}>
        <DialogSection
          section="people"
          onSelectCSV={section => {
            setSection(section);
            openFilePicker();
            props.clearSelections();
          }}
          onEditSettings={section => {}}
        />
        <DialogSection
          section="company"
          onSelectCSV={section => {
            setSection(section);
            openFilePicker();
            props.clearSelections();
          }}
          onEditSettings={section => {}}
        />
      </div>
    </div>
  );
}

export default connect(null, {
  toggleCSVSelectionPopup,
  clearSelections,
})(DialogEntry);
