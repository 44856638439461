import React, {useState} from 'react';
import {connect} from 'react-redux';
import {classNames} from '@commonFunction';

import IconFav from '../../../search/results/cells/icons/fav';
import SubCompany from '../../../search/results/cells//components/subcompany';
import SocialLinks from '../../../search/results/cells//components/sociallinks';
import IconFaved from '../../../search/results/cells/icons/faved';

import IconUpdate from '../../../search/results/cells/icons/update';
import {
  addPersonTolist,
  updateRevealedPeople,
  tooglePeopleFav,
  FILTER_TYPE_MYLIST,
  zapierHook,
} from '../../../search/actions';
import LabelDropdownBlock from '../../../mylist/components/labeldropdownblock';
import {labelNotAssigned} from '../../../mylist/constants';

import LocaleStrings from '../../../../languages';
import Compositesubcontact from '../../../search/results/cells/components/compositesubcontact';
import Combinedicons from '../../../search/results/cells/components/combinedicons';

function ContactCardCell(props) {
  const {
    person,
    selected,
    onCheckUnCheck,
    onOpenCreateList,
    onUpdateAvailableClick,
  } = props;
  const {
    contactname,
    jobtitle,
    company,
    contactdetails,
    linkedin,
    facebook,
    twitter,
    isfav,
    revealed,
    addedtolist,
    listid,
    new_contact_data_available,
    new_company_data_available,
    new_designation_data_available,
    new_personal_email_data_available,
    new_business_email_data_available,
    new_phone_number_data_available,
    contacttag,
  } = person;

  const [setPerson] = useState();
  const [processingExport, setProcessingExport] = useState(false);

  const [showList, setShowList] = useState(false);

  const filterType = FILTER_TYPE_MYLIST;

  const connectToZoho = () => {
    // setProcessingExport(true);
    // let crmDetails = props.crmDetails;
    // if (crmDetails.zoho) {
    //   let peopleIdArr = [{peopleid}];
    //   props.addCRMLead(
    //     props.session,
    //     {
    //       peopleids: peopleIdArr,
    //       lead_source: 'Kipplo Reveal Contact',
    //     },
    //     'zoholead',
    //     resp => {
    //       setProcessingExport(false);
    //       if (resp.success === 1) {
    //         // props.saveSelectedDPeople(props.session, []);
    //         toast.success(LocaleStrings.zoho_success);
    //       } else {
    //         toast.error(LocaleStrings.common_fail_message);
    //       }
    //     },
    //   );
    // } else {
    //   navigate('/settings/crm');
    // }
  };

  const connectToSalesForce = () => {
    // setProcessingExport(true);
    // let crmDetails = props.crmDetails;
    // if (crmDetails.salesforce) {
    //   let peopleIdArr = [{peopleid}];
    //   props.addCRMLead(
    //     props.session,
    //     {
    //       peopleids: peopleIdArr,
    //       lead_source: 'Kipplo Reveal Contact',
    //     },
    //     'salesforce',
    //     resp => {
    //       console.log('resp', resp);
    //       setProcessingExport(false);
    //       if (resp.success === 1) {
    //         // props.saveSelectedDPeople(props.session, []);
    //         toast.success(LocaleStrings.salesforce_success);
    //       } else {
    //         toast.error(LocaleStrings.common_fail_message);
    //       }
    //     },
    //   );
    // } else {
    //   navigate('/settings/crm');
    // }
  };

  const connectToZapier = () => {
    // setProcessingExport(true);
    // let crmDetails = props.crmDetails;
    // if (crmDetails.zapier) {
    //   let peopleIdArr = [{peopleid}];
    //   props.zapierHook(
    //     props.session,
    //     {
    //       peopleids: peopleIdArr,
    //       lead_source: 'Kipplo Reveal Contact',
    //     },
    //     resp => {
    //       console.log('resp', resp);
    //       setProcessingExport(false);
    //       if (resp.success === 1) {
    //         toast.success(LocaleStrings.zapier_success);
    //       } else {
    //         toast.error(LocaleStrings.common_fail_message);
    //       }
    //     },
    //   );
    // } else {
    //   navigate('/settings/crm');
    // }
  };

  const exportToCSV = () => {
    // setProcessingExport(true);
    // let JSONData = {};
    // JSONData['revealedcontacts'] = [person];
    // JSONToCSVConvertor(JSONData);
  };
  // // called from exportBills for downloading report
  // const JSONToCSVConvertor = JSONData => {
  //   var XLSX = require('xlsx');
  //   let wb = XLSX.utils.book_new();

  //   let arrjsonn = [];
  //   _.forEach(JSONData.revealedcontacts, function (person) {
  //     const {
  //       contactname,
  //       jobtitle,
  //       company,
  //       contactdetails,
  //       linkedin,
  //       contacttag,
  //     } = person;

  //     const personalEmails = contactdetails.filter(
  //       contact => contact.type === 'personalemail' && !contact.encrypted,
  //     );
  //     const businessEmails = contactdetails.filter(
  //       contact => contact.type === 'businessemail' && !contact.encrypted,
  //     );

  //     const directDials = contactdetails.filter(
  //       contact => contact.type === 'directdial' && !contact.encrypted,
  //     );

  //     let personalemailcomaseperated = personalEmails
  //       .map(contact => contact.values)
  //       .join(',');
  //     let businessemailcomaseperated = businessEmails
  //       .map(contact => contact.values)
  //       .join(',');

  //     let phonenumbersomaseperated = directDials
  //       .map(contact => contact.values)
  //       .join(',');

  //     var obj = {
  //       ContactName: contactname,
  //       JobTitle: jobtitle,
  //       Company: company.title,
  //       Linkedin_url: linkedin,
  //       Label: contacttag ? contacttag.title : 'Not assigned',
  //       Personal_Emails: personalemailcomaseperated,
  //       Business_Emails: businessemailcomaseperated,
  //       Cell_numbers: phonenumbersomaseperated,
  //     };

  //     arrjsonn.push(obj);
  //   });

  //   let JSONDataOBJ = {
  //     revealedcontacts: arrjsonn,
  //   };
  //   _.map(JSONDataOBJ, (item, index) => {
  //     /* Create a worksheet */
  //     var ws = XLSX.utils.json_to_sheet(item);
  //     var sheetName = index.replace(/[\/\\:\[\]*?]/g, '_');
  //     if (sheetName.length > 30) {
  //       sheetName = sheetName.substr(0, 28) + '...';
  //     }

  //     XLSX.utils.book_append_sheet(wb, ws, sheetName);
  //   });
  //   XLSX.writeFile(wb, 'revealedcontacts.csv');

  //   setProcessingExport(false);
  // };

  return (
    <tr key={contactname} className={selected ? 'bg-gray-50' : undefined}>
      <td className="group relative px-7 py-2">
        {selected && (
          <div className="absolute inset-y-0 left-0 w-0.5 bg-primary" />
        )}
        <input
          type="checkbox"
          className="absolute left-4 top-1/2 -mt-2 h-4 w-4 rounded border-gray-500 text-primary focus:ring-primary group-hover:cursor-pointer"
          value={contactname}
          checked={selected}
          onChange={e => onCheckUnCheck(e.target.checked)}
        />
      </td>
      <td
        className={classNames(
          'group py-4 pr-3 hover:cursor-pointer relative px-7 gap-2 flex flex-col',
        )}
        onClick={() => {
          onCheckUnCheck(!selected);
        }}>
        <div className="relative flex gap-2">
          <div
            className={classNames(
              'whitespace-nowrap text-xs font-medium w-fit truncate',
              selected ? 'text-primary' : 'text-gray-900',
            )}>
            {contactname}
          </div>
          {!linkedin && !twitter && !facebook ? null : (
            <div>
              <SocialLinks
                linkedin={linkedin}
                twitter={twitter}
                facebook={facebook}
              />
            </div>
          )}
          {new_company_data_available ||
          new_designation_data_available ||
          new_personal_email_data_available ||
          new_business_email_data_available ||
          new_phone_number_data_available ? (
            <div
              className="w-2 h-2 ml-2 mt-0.5 hover:cursor-pointer tooltip"
              onClick={e => onUpdateAvailableClick(person)}>
              <IconUpdate />
              <div className="tooltiptext tooltip-right">
                <div className="text-xs">
                  {LocaleStrings.revealedContacts.updateavailable}
                </div>
                {new_company_data_available ? (
                  <div className="text-xs">
                    - {LocaleStrings.revealedContacts.company}
                  </div>
                ) : (
                  ''
                )}
                {new_designation_data_available ? (
                  <div className="text-xs">
                    - {LocaleStrings.revealedContacts.designation}
                  </div>
                ) : (
                  ''
                )}
                {new_personal_email_data_available ? (
                  <div className="text-xs">
                    - {LocaleStrings.revealedContacts.personalemail}
                  </div>
                ) : (
                  ''
                )}
                {new_business_email_data_available ? (
                  <div className="text-xs">
                    - {LocaleStrings.revealedContacts.businessemail}
                  </div>
                ) : (
                  ''
                )}
                {new_phone_number_data_available ? (
                  <div className="text-xs">
                    - {LocaleStrings.revealedContacts.cell}
                  </div>
                ) : (
                  ''
                )}
              </div>
            </div>
          ) : null}
        </div>
        <div className="relative flex">
          <div className="text-xs text-gray-500">{jobtitle}</div>
        </div>

        <div className="text-xs text-gray-500 truncate">{company.location}</div>
        {/* <div
          className="absolute right-2 top-1/2 group-hover:opacity-100 opacity-0"
          onClick={() => {
            navigator.clipboard.writeText(contactname);
            props.onCopySuccess();
          }}>
          <IconCopy />
        </div> */}
      </td>
      <td className="group whitespace-nowrap px-3 py-4 hover:bg-gray-50 hover:cursor-pointer relative">
        <div className="flex flex-col gap-2">
          {contactdetails
            .filter(
              contact =>
                contact.type === 'personalemail' ||
                contact.type === 'businessemail',
            )
            .map(contact => (
              <div key={`${contactname}-${contact.type}`}>
                <Compositesubcontact
                  {...props}
                  {...contact}
                  {...person}
                  contactname={contactname}
                  contacttype="email"
                  onUpgradePlan={props.onUpgradePlan}
                  onBuyAddon={props.onBuyAddon}
                  onClickFlag={props.onClickFlag}
                  onRevealCompletion={updatedPerson => {
                    const contactIndex = props.filteredPeople.data.findIndex(
                      person => person.peopleid === updatedPerson.peopleid,
                    );
                    props.filteredPeople.data[contactIndex] = updatedPerson;

                    props.updateRevealedPeople(props.filteredPeople);
                  }}
                />
              </div>
            ))}
          {contactdetails
            .filter(contact => contact.type === 'directdial')
            .map(contact => (
              <div key={`${contactname}-${contact.type}`}>
                <Compositesubcontact
                  {...props}
                  {...contact}
                  {...person}
                  contactname={contactname}
                  contacttype="phone"
                  onUpgradePlan={props.onUpgradePlan}
                  onBuyAddon={props.onBuyAddon}
                  onClickFlag={props.onClickFlag}
                  onRevealCompletion={updatedPerson => {
                    const contactIndex = props.filteredPeople.data.findIndex(
                      person => person.peopleid === updatedPerson.peopleid,
                    );
                    props.filteredPeople.data[contactIndex] = updatedPerson;

                    props.updateRevealedPeople(props.filteredPeople);
                  }}
                />
              </div>
            ))}
        </div>
      </td>
      <td className="group px-3 py-4 relative">
        <div className="group/company relative flex">
          <div className="text-sm text-primary">
            <SubCompany
              {...company}
              new_company_data_available={new_company_data_available}
              cardView
            />
          </div>
          {/* {new_company_data_available ? (
            <div className=" hover:cursor-pointer tooltip">
              <IconUpdate />
              <span className="tooltiptext tooltip-right">
                {LocaleStrings.revealedContacts.updateavailable}
              </span>
            </div>
          ) : null} */}
        </div>
        {/* <div
          className="absolute right-2 top-6 group-hover:opacity-100 opacity-0"
          onClick={() => {
            navigator.clipboard.writeText(company.title);
            props.onCopySuccess();
          }}>
          <IconCopy />
        </div> */}
      </td>
      <td className="px-3 py-4 text-sm text-gray-500 justify-items-center">
        <div className="justify-start items-start gap-1 inline-flex relative">
          <LabelDropdownBlock
            tag={contacttag}
            labelNotAssigned={labelNotAssigned}
            onLabelSelection={label => {
              props.onLabelSelection(label);
            }}
          />
        </div>
      </td>

      <td className="whitespace-nowrap py-4 pl-3 pr-8">
        <div className="group relative flex gap-2 justify-end items-center">
          <Combinedicons {...props} />
          <div
            className="flex justify-center items-center"
            onClick={() => {
              const newpeople = {...props.person, isfav: !isfav};
              const index = props.filteredPeople.data.findIndex(
                t => t.peopleid === props.person.peopleid,
              );
              props.filteredPeople.data[index] = newpeople;
              props.updateRevealedPeople(props.filteredPeople);
            }}>
            {isfav ? <IconFaved /> : <IconFav />}
          </div>
        </div>
      </td>
    </tr>
  );
}

function mapStateToProps(state) {
  var {session, user, savedFilters} = state;
  return {
    session,
    user,
    savedFilters,
    availableLists: state.availableLists,
    filteredPeople: state.filteredRevealedContacts,
    crmDetails: state.crmDetails,
  };
}

export default connect(mapStateToProps, {
  updateRevealedPeople,
  addPersonTolist,
  tooglePeopleFav,
  zapierHook,
})(ContactCardCell);
