import IconFlag from '../icons/flag';
import IconEmail from '@assets/images/searchmodule/contact/email.svg';
import IconUnknownEmail from '@assets/images/searchmodule/contact/emailunknown.svg';
import IconValidEmail from '@assets/images/searchmodule/contact/emailvalid.svg';
import IconNotAvailableEmail from '@assets/images/searchmodule/contact/emailnotavailable.svg';
import IconDirect from '@assets/images/searchmodule/contact/phone.svg';
import IconCopyNormal from '../icons/copynormal';
import {classNames} from '@commonFunction';
import IconRevealContact from '../icons/revealcontact';
import {connect} from 'react-redux';
import {TRIALPLANID} from '../../../../../../common/constants';
import IconLock from '../icons/lock';
import {useEffect, useState} from 'react';
import {revealContact} from '../../../actions';
import {addCRMLead} from '../../../actions';
import toast, {Toaster} from 'react-hot-toast';
import IconUpvote from '../icons/upvote';
import IconDownvote from '../icons/downvote';

const noEmail = 'No email found';
const noContact = 'No phone number found';

function CompositeSubContact(props) {
  const {
    type,
    values,
    validation,
    available,
    encrypted,
    cardView,
    contacttype,
    peopleid,
    searchlinkedin,
  } = props;

  const IconSize = searchlinkedin ? 18 : 14;

  // console.log('PROPS => ', props);

  const isvalid = validation === 'valid';

  const [inProcess, setInProcess] = useState(false);

  const renderEmailTitle = () => {
    switch (type) {
      case 'personalemail':
        return 'Email';
      case 'businessemail':
        return 'Work';
    }
  };

  const renderPhoneTitle = () => {
    return 'Cell';
  };

  const renderPhoneIcon = () => {
    return <img src={IconDirect} width={IconSize} height={IconSize} />;
  };

  const renderEmailIcon = () => {
    switch (type) {
      case 'personalemail':
        return available ? (
          <img src={IconEmail} width={IconSize} height={IconSize} />
        ) : (
          <img src={IconNotAvailableEmail} width={IconSize} height={IconSize} />
        );
      case 'businessemail':
        return encrypted ? (
          <img src={IconEmail} width={IconSize} height={IconSize} />
        ) : available ? (
          isvalid ? (
            <img src={IconValidEmail} width={IconSize} height={IconSize} />
          ) : (
            <img src={IconUnknownEmail} width={IconSize} height={IconSize} />
          )
        ) : (
          <img src={IconNotAvailableEmail} width={IconSize} height={IconSize} />
        );

      default:
        return available ? (
          <img src={IconEmail} width={IconSize} height={IconSize} />
        ) : (
          <img src={IconNotAvailableEmail} width={IconSize} height={IconSize} />
        );
    }
  };

  const renderIcon = contacttype => {
    if (
      type === 'personalemail' &&
      props.credits.personal_emailcredits === 0 &&
      encrypted
    ) {
      return <IconLock size={searchlinkedin ? 14 : 12} />;
    } else if (
      type === 'businessemail' &&
      props.credits.business_emailcredits === 0 &&
      encrypted
    ) {
      return <IconLock size={searchlinkedin ? 14 : 12} />;
    } else if (
      type === 'directdial' &&
      props.credits.contactcredits === 0 &&
      encrypted
    ) {
      return <IconLock size={searchlinkedin ? 14 : 12} />;
    }

    return contacttype === 'email' ? renderEmailIcon() : renderPhoneIcon();
  };

  // updgrade plan page redirection
  const _openUpgradPlan = e => {
    props.onUpgradePlan();
  };

  // addon page redirection
  const _handleAddon = e => {
    props.onBuyAddon();
  };

  const renderOptions = value => {
    if (
      type === 'personalemail' &&
      props.credits.personal_emailcredits === 0 &&
      encrypted
    ) {
      return (
        <span
          className="vertical-0 text-primary text-xs font-semibold hover:cursor-pointer"
          onClick={e => _openUpgradPlan(e)}>
          {'Upgrade to reveal'}
        </span>
      );
    } else if (
      type === 'businessemail' &&
      props.credits.business_emailcredits === 0 &&
      encrypted
    ) {
      return (
        <span
          className="vertical-0 text-primary text-xs font-semibold hover:cursor-pointer"
          onClick={e => _openUpgradPlan(e)}>
          {'Upgrade to reveal'}
        </span>
      );
    } else if (
      type === 'directdial' &&
      props.credits.contactcredits === 0 &&
      encrypted
    ) {
      return (
        <span
          className="vertical-0 text-primary text-xs font-semibold hover:cursor-pointer"
          onClick={e => {
            // if (
            //   props.credits.role === 'client' &&
            //   props.credits.planid != TRIALPLANID
            // )
            //   _handleAddon(e);
            // else _openUpgradPlan(e);
            _openUpgradPlan(e);
          }}>
          {/* {props.credits.role === 'client' &&
          props.credits.planid != TRIALPLANID
            ? 'Buy Addon to reveal'
            : 'Upgrade to reveal'} */}
          {'Upgrade to reveal'}
        </span>
      );
    }
    return value;
  };

  const availableToReveal = () => {
    if (
      type === 'personalemail' &&
      props.credits.personal_emailcredits === 0 &&
      encrypted
    ) {
      return false;
    } else if (
      type === 'businessemail' &&
      props.credits.business_emailcredits === 0 &&
      encrypted
    ) {
      return false;
    } else if (
      type === 'directdial' &&
      props.credits.contactcredits === 0 &&
      encrypted
    ) {
      return false;
    }
    return true;
  };

  const formatData = details => {
    let contactdetails = details.contactdetails;

    let personalEmails = contactdetails.filter(
      contact => contact.type === 'personalemail',
    );
    let businessEmails = contactdetails.filter(
      contact => contact.type === 'businessemail',
    );
    let directDials = contactdetails.filter(
      contact => contact.type === 'directdial',
    );

    const emptyContactDetails = [];

    if (personalEmails.length > 0) {
      emptyContactDetails.push(personalEmails[0]);
    } else {
      if (type === 'personalemail') {
        emptyContactDetails.push({
          values: [noEmail],
          validation: 'unknown',
          encrypted: true,
          type: 'personalemail',
          available: false,
        });
      } else {
        emptyContactDetails.push({
          values: ['*******@*****.***'],
          validation: 'unknown',
          encrypted: true,
          type: 'personalemail',
          available: false,
        });
      }
    }

    if (businessEmails.length > 0) {
      emptyContactDetails.push(businessEmails[0]);
    } else {
      if (type === 'businessemail') {
        emptyContactDetails.push({
          values: [noEmail],
          validation: 'unknown',
          encrypted: true,
          type: 'businessemail',
          available: false,
        });
      } else {
        emptyContactDetails.push({
          values: ['*******@*****.***'],
          validation: 'unknown',
          encrypted: true,
          type: 'businessemail',
          available: false,
        });
      }
    }

    if (directDials.length > 0) {
      emptyContactDetails.push(directDials[0]);
    } else {
      if (type === 'directdial') {
        emptyContactDetails.push({
          values: [noContact],
          validation: 'unknown',
          encrypted: true,
          type: 'directdial',
          available: false,
        });
      } else {
        emptyContactDetails.push({
          values: ['+**********'],
          validation: 'unknown',
          encrypted: true,
          type: 'directdial',
          available: false,
        });
      }
    }
    contactdetails = emptyContactDetails;

    return {...details, contactdetails};
  };

  useEffect(() => {
    if (inProcess) {
      props.revealContact(
        props.session,
        {peopleid: peopleid, type: type, action: 'reveal'},
        res => {
          if (res && res.success === 1) {
            const formattedData = formatData(res.data, type);
            // let peopleIdArr = [];
            // let obj = {};
            // obj.peopleid = peopleid;
            // peopleIdArr.push(obj);
            // //Salesforce Sync
            // props.addCRMLead(
            //   props.session,
            //   {
            //     peopleids: peopleIdArr,
            //     lead_source: 'Reveal',
            //     type: type,
            //   },
            //   'salesforce',
            //   resp => {},
            // );
            // //Zoho Sync
            // props.addCRMLead(
            //   props.session,
            //   {
            //     peopleids: peopleIdArr,
            //     lead_source: 'Reveal',
            //     type: type,
            //   },
            //   'zoholead',
            //   resp => {},
            // );
            props.onRevealCompletion(formattedData);
          }
          setInProcess(false);
        },
      );
    }
  }, [inProcess]);

  const onRevealContact = () => {
    setInProcess(true);
  };

  return (
    <div className="flex flex-col gap-2 mb-2">
      {values.map(value => {
        return (
          <div
            className={classNames(
              'justify-start items-center gap-2 inline-flex group',
              searchlinkedin ? 'relative w-[450px]' : '',
            )}
            key={value}>
            <div
              className={classNames(
                'bg-primaryHover flex-col justify-start items-center gap-2.5 inline-flex',
                searchlinkedin ? 'w-12 px-2 py-1' : 'w-11 px-1 py-0.5',
              )}>
              <div
                className={classNames(
                  'text-primary',
                  searchlinkedin ? ' text-xs' : ' text-[10px]',
                )}>
                {contacttype === 'email'
                  ? renderEmailTitle()
                  : renderPhoneTitle()}
              </div>
            </div>
            {inProcess ? (
              <div className="animate-pulse">
                <div className="w-60 h-2 bg-gray-100 rounded mb-0.5"></div>
                <div className="w-54 h-2 bg-gray-100 rounded"></div>
              </div>
            ) : (
              <div className="justify-start items-center gap-2 inline-flex">
                <div>{renderIcon(contacttype)}</div>
                <div
                  className={classNames(
                    'text-gray-900 leading-tight truncate',
                    searchlinkedin ? 'w-[290px] text-sm' : 'text-[10px]',
                  )}>
                  {renderOptions(value)}
                </div>
                <div
                  className="relative hover:cursor-pointer"
                  onClick={() => {
                    props.onClickFlag({
                      contacttype: type,
                      contact: value,
                      action: 'upvote',
                    });
                  }}>
                  {available && !encrypted ? (
                    <IconUpvote
                      className="hover:scale-110"
                      size={searchlinkedin ? 14 : 12}
                    />
                  ) : null}
                </div>
                <div
                  className="relative hover:cursor-pointer"
                  onClick={() => {
                    props.onClickFlag({
                      contacttype: type,
                      contact: value,
                      action: 'downvote',
                    });
                  }}>
                  {available && !encrypted ? (
                    <IconDownvote className="hover:scale-110" />
                  ) : null}
                </div>
                <div
                  className={classNames(
                    'absolute right-2 hover:cursor-pointer',
                  )}>
                  <div
                    className="w-4 h-4 relative"
                    onClick={() => {
                      if (!inProcess) {
                        if (available && !encrypted) {
                          navigator.clipboard.writeText(value);
                          toast.success('Copied successfully');
                        } else if (availableToReveal()) {
                          onRevealContact();
                        }
                      }
                    }}>
                    {available && !encrypted ? (
                      <IconCopyNormal size={searchlinkedin ? 14 : 12} />
                    ) : availableToReveal() ? (
                      <IconRevealContact size={searchlinkedin ? 14 : 12} />
                    ) : null}
                  </div>
                </div>
              </div>
            )}
          </div>
        );
      })}
    </div>
  );
}

function mapStateToProps(state) {
  var {session, user, appuserData} = state;
  return {
    session,
    user,
    credits: appuserData.credits,
  };
}

export default connect(mapStateToProps, {
  revealContact,
  addCRMLead,
})(CompositeSubContact);
