import {postRequest, postRequestZapier} from '@networkCall';

import {INSTANCE_URL} from '@constant';

import {defaultFilters} from '../reducers';
import {
  ENTERPRISE_MONTHLY,
  ENTERPRISE_YEARLY,
} from '../../../../common/constants';

// All Exports
export const FILTERS = 'FILTERS';
export const CLEAR_ALL_FILTERS = 'CLEAR_ALL_FILTERS';
export const RECENT_SEARCHES = 'RECENT_SEARCHES';
export const PEOPLE_FILTER = 'PEOPLE_FILTER';
export const PEOPLE_FILTER_SUPPORT_DATA = 'PEOPLE_FILTER_SUPPORT_DATA';
export const PEOPLE_FAV_FILTER = 'PEOPLE_FAV_FILTER';

export const FILTER_TYPE_PEOPLE = 'PEOPLE';
export const FILTER_TYPE_FAV_PEOPLE = 'FAV_PEOPLE';
export const FILTER_TYPE_REVEALED_PEOPLE = 'REVEALED_PEOPLE';
export const FILTER_TYPE_MYLIST = 'MYLIST_PEOPLE';

export const FILTER_TYPE_REVEALED_CONTACTS = 'REVEALED_CONTACTS';

export const AVAILABLE_LISTS = 'AVAILABLE_LISTS';
export const AVAILABLE_COMPANY_LISTS = 'AVAILABLE_COMPANY_LISTS';

export const AVAILABLE_FILTERS = 'AVAILABLE_FILTERS';

export const TOGGLE_FILTERS_SIDE_POPUP = 'TOGGLE_FILTERS_SIDE_POPUP';

export const FILTER_TYPE_COMPANY = 'COMPANY';
export const FILTER_TYPE_FAV_COMPANY = 'FAV_COMPANY';
export const COMPANY_FAV_FILTER = 'COMPANY_FAV_FILTER';
export const COMPANY_FILTER = 'COMPANY_FILTER';

export const TOGGLE_MINIMIZE_FILTERS = 'TOGGLE_MINIMIZE_FILTERS';

export const TOGGLLING_FILTERS = 'TOGGLLING_FILTERS';

export const TECHNOLOGY_SEARCH = 'TECHNOLOGY_SEARCH';

export const CRM_SELECTED_PEOPLE = 'CRM_SELECTED_PEOPLE';
export const CRM_DETAILS = 'CRM_DETAILS';
export const CRM_TYPE = 'CRM_TYPE';
export const MAP_FIELD_MODAL = 'MAP_FIELD_MODAL';

export const COMPANY_DETAILS = 'COMPANY_DETAILS';
export const PEOPLE_DETAILS = 'PEOPLE_DETAILS';
export const ZOHO_FIELDS = 'ZOHO_FIELDS';
export const SF_FIELDS = 'SF_FIELDS';
export const WEB_HOOK_MODAL = 'WEB_HOOK_MODAL';

export function saveFilter(session, allFilters, callback) {
  const filters = allFilters;
  const hasExcludeFilter =
    filters.exclude.revealedbusinessemails ||
    filters.exclude.revealedpersonalemails ||
    filters.exclude.revealedphonenumbers ||
    filters.exclude.revealedtech ||
    filters.exclude.revealedcontacts;

  const hasLocations =
    filters.location.contact.length > 0 || filters.location.hq.length > 0;

  const hasIndustryCode = false; //filters.industry.type.length > 0;

  const hasNofilters =
    filters['contactname'].length === 0 &&
    filters['department'].length === 0 &&
    filters['jobtitle'].length === 0 &&
    filters['companyname'].length === 0 &&
    filters['searchtype'].length === 0 &&
    filters['seniority'].length === 0 &&
    filters['industry'].length === 0 &&
    filters['technology'].length === 0 &&
    filters['foundedyear'].length === 0 &&
    filters['headcount'].length === 0 &&
    filters['revenue'].length === 0 &&
    !hasExcludeFilter &&
    !hasLocations &&
    !hasIndustryCode;

  let payload = {...allFilters};
  if (hasNofilters) {
    const allKeys = Object.keys(allFilters);
    payload = {};
    allKeys.map(key => {
      if (key !== 'hasFilters') payload[key] = allFilters[key];
    });
  } else {
    payload = {...allFilters, hasFilters: true};
  }
  return dispatch => {
    dispatch({
      type: FILTERS,
      payload,
    });
  };
}

export function clearFilter() {
  return dispatch => {
    const filters = defaultFilters;

    filters.exclude.revealedbusinessemails = false;
    filters.exclude.revealedpersonalemails = false;
    filters.exclude.revealedphonenumbers = false;
    filters.exclude.revealedtech = false;
    filters.exclude.revealedcontacts = false;

    filters['contactname'] = [];
    filters['department'] = [];
    filters['jobtitle'] = [];
    filters['companyname'] = [];
    filters['location'] = {contact: [], hq: []};
    filters['searchtype'] = [];
    filters['seniority'] = [];
    filters['industry'] = [];
    filters['technology'] = [];
    filters['foundedyear'] = [];
    filters['revenue'] = [];

    dispatch({
      type: CLEAR_ALL_FILTERS,
      payload: {...filters},
    });
  };
}

export function saveRecentSearch(session, search, callback) {
  return dispatch => {
    dispatch({
      type: RECENT_SEARCHES,
      payload: search,
    });
  };
}

const normalizeParams = params => {
  let contactname = params['contactname'];
  contactname = contactname.map(contact => contact.title);

  let department = params['department'];
  department = department.map(obj => ({
    text: obj.value,
    exclude: obj.action === 'exclude',
  }));

  let jobtitle = params['jobtitle'];
  jobtitle = jobtitle.map(obj => obj.value);

  let companyname = params['companyname'];
  companyname = companyname.map(obj => ({
    text: obj.value,
    exclude: obj.action === 'exclude',
  }));

  const location = params['location'];

  const contact = location['contact'].map(obj => ({
    text: obj.value,
    exclude: obj.action === 'exclude',
  }));

  const hq = location['hq'].map(obj => ({
    text: obj.value,
    exclude: obj.action === 'exclude',
  }));

  let seniority = params['seniority'];
  seniority = seniority.map(obj => obj.value);

  let searchtype = params['searchtype'];
  searchtype = searchtype.map(obj => obj.value);

  let technology = params['technology'];
  technology = technology.map(obj => ({
    text: obj.value,
    exclude: obj.action === 'exclude',
  }));

  let headcount = params['headcount'];
  headcount = headcount.map(obj => obj.value);

  let industry = params['industry'];
  industry = industry.map(obj => ({
    text: obj.value,
    exclude: obj.action === 'exclude',
  }));

  let industrycode = params['industrycode'];
  let type = industrycode.type;
  if (type === 'SIC') {
    industrycode.type = 'siccodes';
  } else if (type === 'NAICS') {
    industrycode.type = 'naicscodes';
  }

  return {
    ...params,
    contactname,
    department,
    companyname,
    location: {contact, hq},
    seniority,
    jobtitle,
    searchtype,
    technology,
    headcount,
    industry,
    industrycode,
  };
};

let abortController = null;

export const filterPeople = (
  session,
  page,
  limit,
  sortby,
  sortdirection,
  params,
  filterType,
  appuserData,
  callback,
) => {
  const suffix =
    filterType === FILTER_TYPE_PEOPLE ? 'people' : 'favourite_people';

  const url = `${INSTANCE_URL}/api/v2/people_search/${suffix}`;

  // Cancel the previous request if it's still ongoing
  if (abortController) {
    abortController.abort();
    console.log('Previous request canceled');
  }

  abortController = new AbortController();
  const signal = abortController.signal;

  const values = {
    ...normalizeParams(params),
    limit,
    page,
    sortby,
    sortdirection,
  };

  return dispatch => {
    postRequest(
      url,
      values,
      session,
      dispatch,
      res => {
        dispatch({
          type:
            filterType === FILTER_TYPE_PEOPLE
              ? PEOPLE_FILTER
              : PEOPLE_FAV_FILTER,
          payload: {
            totalcount: res.totalcount,
            pages: res.numpages,
            data: res.data,
            headcount: Array.isArray(res.headcount) ? undefined : res.headcount,
          },
        });

        const onEnterprisePlan =
          appuserData.credits.role === 'client' &&
          (appuserData.credits.planid === ENTERPRISE_YEARLY ||
            appuserData.credits.planid === ENTERPRISE_MONTHLY);

        const totalcount = res.totalcount;

        const showTechnologyBlock =
          !onEnterprisePlan && params.technology.length > 0 && totalcount > 1;
        dispatch({
          type: TECHNOLOGY_SEARCH,
          payload: {
            totalcount,
            showTechnologyBlock,
          },
        });

        callback({success: 1});
      },
      error => {
        callback({success: 0, message: error});
      },
      signal,
    );
  };
};

export const filterCompany = (
  session,
  page,
  limit,
  sortby,
  sortdirection,
  params,
  filterType,
  appuserData,
  callback,
) => {
  const suffix =
    filterType === FILTER_TYPE_COMPANY ? 'company' : 'favourite_company';

  const url = `${INSTANCE_URL}/api/v2/company_search/${suffix}`;

  const values = {
    ...normalizeParams(params),
    limit,
    page,
    sortby,
    sortdirection,
  };

  return dispatch => {
    postRequest(
      url,
      values,
      session,
      dispatch,
      res => {
        dispatch({
          type:
            filterType === FILTER_TYPE_COMPANY
              ? COMPANY_FILTER
              : COMPANY_FAV_FILTER,
          payload: {
            totalcount: res.totalcount,
            pages: res.numpages,
            data: res.data,
            headcount: Array.isArray(res.headcount) ? undefined : res.headcount,
          },
        });

        const onEnterprisePlan =
          appuserData.credits.role === 'client' &&
          (appuserData.credits.planid === ENTERPRISE_YEARLY ||
            appuserData.credits.planid === ENTERPRISE_MONTHLY);

        const totalcount = res.totalcount;

        //Check if technology filter has data
        const showTechnologyBlock =
          !onEnterprisePlan && params.technology.length > 0 && totalcount > 1;

        dispatch({
          type: TECHNOLOGY_SEARCH,
          payload: {
            totalcount,
            showTechnologyBlock,
          },
        });

        callback({success: 1});
      },
      error => {
        callback({success: 0, message: error});
      },
    );
  };
};

export const preloadPeopleSearchData = (session, callback) => {
  const url = `${INSTANCE_URL}/api/v2/search_field_data`;

  return dispatch => {
    postRequest(
      url,
      {action: 'preload'},
      session,
      dispatch,
      res => {
        dispatch({
          type: PEOPLE_FILTER_SUPPORT_DATA,
          payload: res,
        });

        callback({success: 1});
      },
      error => {
        callback({success: 0, message: error});
      },
    );
  };
};

export const fieldSearch = (session, params, callback) => {
  const url = `${INSTANCE_URL}/api/v2/search_field_data`;

  return dispatch => {
    postRequest(
      url,
      params,
      session,
      dispatch,
      res => {
        callback({success: 1, data: res});
      },
      error => {
        callback({success: 0, message: error});
      },
    );
  };
};

export const updatePeople = data => {
  return dispatch => {
    dispatch({
      type: PEOPLE_FILTER,
      payload: data,
    });
  };
};

export const updateFavPeople = data => {
  return dispatch => {
    dispatch({
      type: PEOPLE_FAV_FILTER,
      payload: data,
    });
  };
};

export const tooglePeopleFav = (session, params, callback) => {
  const url = `${INSTANCE_URL}/api/v2/field_actions`;

  return dispatch => {
    postRequest(
      url,
      params,
      session,
      dispatch,
      res => {
        callback({success: 1, data: res});
      },
      error => {
        callback({success: 0, message: error});
      },
    );
  };
};

export const fetchLists = (session, params, callback) => {
  const url = `${INSTANCE_URL}/api/v2/pagedataprovider`;

  const values = {...params, action: 'availablelists'};

  return dispatch => {
    postRequest(
      url,
      values,
      session,
      dispatch,
      res => {
        dispatch({
          type: AVAILABLE_LISTS,
          payload: res.results,
        });
        callback({success: 1});
      },
      error => {
        callback({success: 0, message: error});
      },
    );
  };
};

export const createList = (session, params, callback) => {
  const url = `${INSTANCE_URL}/api/v2/field_actions`;

  const values = {...params, action: 'createlist'};

  return dispatch => {
    postRequest(
      url,
      values,
      session,
      dispatch,
      res => {
        callback({success: 1, data: res});
      },
      error => {
        callback({success: 0, message: error});
      },
    );
  };
};

export const addPersonTolist = (session, params, callback) => {
  const url = `${INSTANCE_URL}/api/v2/field_actions`;

  const values = {...params, action: 'addtolist'};

  return dispatch => {
    postRequest(
      url,
      values,
      session,
      dispatch,
      res => {
        callback({success: 1, data: res});
      },
      error => {
        callback({success: 0, message: error});
      },
    );
  };
};

export const filterRevealedContact = (
  session,
  page,
  limit,
  sortby,
  sortdirection,
  params,
  appuserData,
  callback,
) => {
  const url = `${INSTANCE_URL}/api/v2/people_search/revealed_contact`;

  const values = {
    ...normalizeParams(params),
    limit,
    page,
    sortby,
    sortdirection,
  };

  return dispatch => {
    postRequest(
      url,
      values,
      session,
      dispatch,
      res => {
        dispatch({
          type: FILTER_TYPE_REVEALED_CONTACTS,
          payload: {
            totalcount: res.totalcount,
            pages: res.numpages,
            data: res.data,
            headcount: Array.isArray(res.headcount) ? undefined : res.headcount,
          },
        });

        const onEnterprisePlan =
          appuserData.credits.role === 'client' &&
          (appuserData.credits.planid === ENTERPRISE_YEARLY ||
            appuserData.credits.planid === ENTERPRISE_MONTHLY);

        const totalcount = res.totalcount;

        const showTechnologyBlock =
          !onEnterprisePlan && params.technology.length > 0 && totalcount > 1;
        dispatch({
          type: TECHNOLOGY_SEARCH,
          payload: {
            totalcount,
            showTechnologyBlock,
          },
        });

        callback({success: 1});
      },
      error => {
        callback({success: 0, message: error});
      },
    );
  };
};

export const updateRevealedPeople = data => {
  return dispatch => {
    dispatch({
      type: FILTER_TYPE_REVEALED_CONTACTS,
      payload: data,
    });
  };
};

export const revealContact = (session, params, callback) => {
  const url = `${INSTANCE_URL}/api/v2/revealcontact`;

  return dispatch => {
    postRequest(
      url,
      params,
      session,
      dispatch,
      res => {
        callback({success: 1, data: res.data});
      },
      error => {
        callback({success: 0, message: error});
      },
    );
  };
};

export const fetchSavedFilters = (session, params, callback) => {
  const url = `${INSTANCE_URL}/api/v2/pagedataprovider`;

  const values = {...params, action: 'savedfilters'};

  return dispatch => {
    postRequest(
      url,
      values,
      session,
      dispatch,
      res => {
        dispatch({
          type: AVAILABLE_FILTERS,
          payload: res.results,
        });
        callback({success: 1});
      },
      error => {
        callback({success: 0, message: error});
      },
    );
  };
};

export const saveFiltersToServer = (session, params, callback) => {
  const url = `${INSTANCE_URL}/api/v2/field_actions`;

  const values = {...params, action: 'savefilter'};

  return dispatch => {
    postRequest(
      url,
      values,
      session,
      dispatch,
      res => {
        callback({success: 1, data: res});
      },
      error => {
        callback({success: 0, message: error});
      },
    );
  };
};

export const addFilterToSavedFilter = (session, params, callback) => {
  const url = `${INSTANCE_URL}/api/v2/field_actions`;

  const values = {...params, action: 'addtofilter'};

  return dispatch => {
    postRequest(
      url,
      values,
      session,
      dispatch,
      res => {
        callback({success: 1, data: res});
      },
      error => {
        callback({success: 0, message: error});
      },
    );
  };
};

export function toggleFiltersSidePopup(open) {
  return dispatch => {
    dispatch({
      type: TOGGLE_FILTERS_SIDE_POPUP,
      payload: open,
    });
  };
}

export function deleteSavedFilter(session, params, callback) {
  const url = `${INSTANCE_URL}/api/v2/field_actions`;

  const values = {...params, action: 'deletesavedfilter'};

  return dispatch => {
    postRequest(
      url,
      values,
      session,
      dispatch,
      res => {
        callback({success: 1, data: res});
      },
      error => {
        callback({success: 0, message: error});
      },
    );
  };
}

export const updateSavedFilters = data => {
  return dispatch => {
    dispatch({
      type: AVAILABLE_FILTERS,
      payload: data,
    });
  };
};

/** COMPANY */

export const updateCompany = data => {
  return dispatch => {
    dispatch({
      type: COMPANY_FILTER,
      payload: data,
    });
  };
};

export const updateFavCompany = data => {
  return dispatch => {
    dispatch({
      type: COMPANY_FAV_FILTER,
      payload: data,
    });
  };
};

export const toogleCompanyFav = (session, params, callback) => {
  const url = `${INSTANCE_URL}/api/v2/field_actions`;

  return dispatch => {
    postRequest(
      url,
      params,
      session,
      dispatch,
      res => {
        callback({success: 1, data: res});
      },
      error => {
        callback({success: 0, message: error});
      },
    );
  };
};

export const fetchCompanyLists = (session, params, callback) => {
  const url = `${INSTANCE_URL}/api/v2/pagedataprovider`;

  const values = {...params, action: 'availablecompanylists'};

  return dispatch => {
    postRequest(
      url,
      values,
      session,
      dispatch,
      res => {
        dispatch({
          type: AVAILABLE_COMPANY_LISTS,
          payload: res.results,
        });
        callback({success: 1});
      },
      error => {
        callback({success: 0, message: error});
      },
    );
  };
};

export const createCompanyList = (session, params, callback) => {
  const url = `${INSTANCE_URL}/api/v2/field_actions`;

  const values = {...params, action: 'createcompanylist'};

  return dispatch => {
    postRequest(
      url,
      values,
      session,
      dispatch,
      res => {
        callback({success: 1, data: res});
      },
      error => {
        callback({success: 0, message: error});
      },
    );
  };
};

export const addCompanyTolist = (session, params, callback) => {
  const url = `${INSTANCE_URL}/api/v2/field_actions`;

  const values = {...params, action: 'addtocompanylist'};

  return dispatch => {
    postRequest(
      url,
      values,
      session,
      dispatch,
      res => {
        callback({success: 1, data: res});
      },
      error => {
        callback({success: 0, message: error});
      },
    );
  };
};

export function toggleMinimizeFilters(toggle) {
  return dispatch => {
    dispatch({
      type: TOGGLE_MINIMIZE_FILTERS,
      payload: toggle,
    });
  };
}

export function togglingFilters(toggle) {
  return dispatch => {
    dispatch({
      type: TOGGLLING_FILTERS,
      payload: toggle,
    });
  };
}

export const addCRMLead = (session, params, method, callback) => {
  const url = `${INSTANCE_URL}/api/v2/crm/${method}`;

  const values = {...params};

  return dispatch => {
    postRequest(
      url,
      values,
      session,
      dispatch,
      res => {
        if (res.success) {
          callback({success: 1});
        } else {
          let errorlog = res.hasOwnProperty('errorlog') ? res.errorlog : [];
          callback({success: 0, errorlog: errorlog});
        }
      },
      error => {
        callback({success: 0, message: error});
      },
    );
  };
};

export function saveSelectedDPeople(session, data, callback) {
  return dispatch => {
    dispatch({
      type: CRM_SELECTED_PEOPLE,
      payload: data,
    });
  };
}

export function fetchCRMDetails(session, callback) {
  const url = `${INSTANCE_URL}/api/v2/crm/crmdetails`;

  const values = {};

  return dispatch => {
    postRequest(
      url,
      values,
      session,
      dispatch,
      res => {
        let sales_mapped_data = res.sales_mapped_data;
        let zoho_mapped_data = res.zoho_mapped_data;
        let hookurl = res.hookurl;
        dispatch({
          type: CRM_DETAILS,
          payload: {
            zoho: res.zoho,
            salesforce: res.salesforce,
            zoho_mapped_data: zoho_mapped_data,
            sales_mapped_data: sales_mapped_data,
            zapier: res.zapier,
            hookurl: res.hookurl,
          },
        });
        callback({success: 1});
      },
      error => {
        callback({success: 0, message: error});
      },
    );
  };
}

export function saveCRMType(session, crmtype = null, callback) {
  return dispatch => {
    dispatch({
      type: CRM_TYPE,
      payload: crmtype,
    });
  };
}

export function fetchCompanyDetails(
  session,
  page,
  limit,
  sortby,
  sortdirection,
  params,
  callback,
) {
  const url = `${INSTANCE_URL}/api/v2/company_search/companydetails`;

  const values = {
    ...params,
    limit,
    page,
    sortby,
    sortdirection,
  };
}

export const zapierHook = (session, params, callback) => {
  const url = `${INSTANCE_URL}/api/v2/zapier_lead/zapierhook`;

  const values = {...params};

  return dispatch => {
    postRequestZapier(
      url,
      values,
      session,
      dispatch,
      res => {
        if (res.success) {
          callback({success: 1});
        } else {
          callback({success: 0});
        }
      },
      error => {
        callback({success: 0, message: error});
      },
    );
  };
};

export function crmDisconnect(session, crmtype, callback) {
  const url = `${INSTANCE_URL}/api/v2/crm/disconnect`;

  const values = {crmtype: crmtype};

  return dispatch => {
    postRequest(
      url,
      values,
      session,
      dispatch,
      res => {
        if (res.success) {
          callback({success: 1});
        } else {
          callback({success: 0});
        }
      },
      error => {
        callback({success: 0, message: error});
      },
    );
  };
}

export function fetchPeopleDetails(
  session,
  page,
  limit,
  sortby,
  sortdirection,
  params,
  callback,
) {
  const url = `${INSTANCE_URL}/api/v2/people_search/peopldetails`;

  const values = {
    ...params,
    limit,
    page,
    sortby,
    sortdirection,
  };
}

export function crmMakeconnection(session, values, callback) {
  const url = `${INSTANCE_URL}/api/v2/crm/makeconnection`;

  return dispatch => {
    postRequest(
      url,
      values,
      session,
      dispatch,
      res => {
        if (res.success) {
          callback({success: 1});
        } else {
          callback({success: 0});
        }
      },
      error => {
        callback({success: 0, message: error});
      },
    );
  };
}

export function mapFieldsModal(isOpen) {
  return {type: MAP_FIELD_MODAL, payload: isOpen};
}

export function updateMappedFields(session, values, callback) {
  const url = `${INSTANCE_URL}/api/v2/crm/mapfields`;

  return dispatch => {
    postRequest(
      url,
      values,
      session,
      dispatch,
      res => {
        if (res.success) {
          callback({success: 1});
        } else {
          callback({success: 0});
        }
      },
      error => {
        callback({success: 0, message: error});
      },
    );
  };
}

export function postEnrich(session, enricharr, callback) {
  const url = `${INSTANCE_URL}/api/v2/enrich_script`;
  const values = {enricharr: enricharr};
  return dispatch => {
    postRequest(
      url,
      values,
      session,
      dispatch,
      res => {
        if (res.success) {
          callback({success: 1});
        } else {
          callback({success: 0});
        }
      },
      error => {
        callback({success: 0, message: error});
      },
    );
  };
}

export function getallZohoFields(session, callback) {
  const url = `${INSTANCE_URL}/api/v2/crm/getallfieldsZoho`;

  return dispatch => {
    postRequest(
      url,
      {},
      session,
      dispatch,
      res => {
        if (res.success) {
          dispatch({
            type: ZOHO_FIELDS,
            payload: {
              fields: res.fields,
            },
          });
          callback({success: 1});
        } else {
          callback({success: 0});
        }
      },
      error => {
        callback({success: 0, message: error});
      },
    );
  };
}

export function getallSFFields(session, callback) {
  const url = `${INSTANCE_URL}/api/v2/crm/getallfieldsSalesforce`;

  return dispatch => {
    postRequest(
      url,
      {},
      session,
      dispatch,
      res => {
        //console.log('res', res);
        if (res.hasOwnProperty('error')) {
          callback({success: 0});
        } else {
          dispatch({
            type: SF_FIELDS,
            payload: {
              fields: res,
            },
          });
          callback({success: 1});
        }
      },
      error => {
        callback({success: 0, message: error});
      },
    );
  };
}

export function webHookModal(isOpen) {
  return {type: WEB_HOOK_MODAL, payload: isOpen};
}

export function addHookUrl(session, values, callback) {
  let url = `${INSTANCE_URL}/api/v2/thomsondata/_table/zapier_webhook`;
  // let body = { resource: { ...values } };
  let body = {resource: [values]};
  return dispatch => {
    postRequest(
      url,
      body,
      session,
      dispatch,
      response => {
        // console.log('res',response)
        callback({success: 1, result: response});
      },
      error => {
        // console.log(error)
        callback({success: 0, message: error});
      },
    );
  };
}

export const revealAllContact = (session, params, callback) => {
  const url = `${INSTANCE_URL}/api/v2/revealallcontacts`;

  return dispatch => {
    postRequest(
      url,
      params,
      session,
      dispatch,
      res => {
        callback({success: 1, data: res.data});
      },
      error => {
        callback({success: 0, message: error});
      },
    );
  };
};

export const massRevealContacts = (session, params, callback) => {
  const url = `${INSTANCE_URL}/api/v2/massrevealcontacts`;

  return dispatch => {
    postRequest(
      url,
      params,
      session,
      dispatch,
      res => {
        callback({success: 1});
      },
      error => {
        callback({success: 0, message: error});
      },
    );
  };
};
